<template>
    <div>
        <div class="d-flex">
            <template v-if="profile.profileRequestStatus == 'pending' && profile.profileRequest == 'allow'">
                <span class="badge badge-primary" style="margin-right:5px;" v-if="manage" @click="saveBtn('accepted')">
                    <span class="text-nowrap">Accept</span>
                </span>
            </template>
            <template v-if="profile.profileRequestStatus != 'declined'">
                <span class="badge badge-danger" v-if="manage" v-b-modal="'leaderboard-decline-modal-' + index">
                    <span class="text-nowrap">Decline</span>
                </span>
            </template>
        </div>
        <b-modal :id="'leaderboard-decline-modal-' + index" hide-footer size="md" centered title="Note" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{ errorMsg }}</b-alert>

                <!-- <p class="mb-0 ">Note</p> -->
                <b-form-textarea class="mb-1" v-model="record.note"></b-form-textarea>

                <div class="modal-footer px-0">
                    <b-button @click="saveBtn('declined')" :disabled="loading" variant="danger">
                        <b-spinner v-if="loading" small />
                        Decline
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../Helpers';
export default {
    components: {
        BButton,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BAlert,
        BSpinner,
        BFormCheckbox,
        ToastificationContent,
        vSelect,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {},
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        async saveBtn(data) {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.record.profileRequestStatus = data;
            this.loading = true;
            try {
                const resp = await axios.put(process.env.VUE_APP_API_URL + "/leaderboard/status/"+this.profile._id,
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitStatusUpdated');
                this.$bvModal.hide(`leaderboard-decline-modal-${this.index}`);
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.note && this.record.profileRequestStatus == 'declined') {
                this.errorMsg = "Note field is required."
                return;
            }
            return "ok";
        },
    },
    props: [
        "manage",
        "profile",
        "index"
    ],
};
</script>
