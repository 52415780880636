<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0 pb-2">
        <div class="m-2">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <div class="row">
                <b-col cols="12" md="3">
                  <label for="class">Grades</label>
                  <v-select class="w-100"
                    v-model="record_filter.grade"
                    placeholder="Select a grade"
                    :clearable="false"
                    :reduce="(get_grades) => get_grades._id"
                    :options="get_grades"
                    label="className"
                    @input="getTopUsers()">
                    <template v-slot:option="option">
                        {{ option.className }}
                    </template>
                  </v-select>
                </b-col>
                <b-col cols="12" md="3">
                  <label for="class">Status</label>
                  <v-select class="w-100"
                    v-model="record_filter.profileRequestStatus"
                    placeholder="Select a status"
                    :clearable="true"
                    :reduce="(get_request_statuses) => get_request_statuses.name"
                    :options="get_request_statuses"
                    label="label"
                    @input="getTopUsers()">
                    <template v-slot:option="option">
                        {{ option.label }}
                    </template>
                  </v-select>
                </b-col>
                <div class="col-md-3">
                  <label for="class">Search</label>
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input v-model="record_filter.search" class="d-inline-block mr-1" placeholder="Search..."
                      @input="filterAppUser" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-table-simple :key="refreshScreen" v-if="get_top_users.total > 0" hover caption-top responsive
          class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Rank#</b-th>
              <b-th>User</b-th>
              <b-th>Progress</b-th>
              <b-th>Requested Date</b-th>
              <b-th>Status</b-th>
              <b-th>History</b-th>
              <b-th>Last Played</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <tr v-for="(item,index) in get_top_users.data" :key="index">
              <td>
                {{ get_top_users.from + index }}
                <!-- {{ item.rank }} -->
              </td>
              <td>
                <b-avatar v-if="item.profile" size="25" :src="`${Helpers.s3URL()}${item.profile.avatar}`" />
                {{ item.userName }}
                <template v-if="item.profile">
                  <span v-if="item.profile.profileRequestStatus != 'accepted'">
                    ({{ item.profile.leaderboardProfileName }})
                  </span>
              </template>
              </td>
              <td>
                {{ item.gradeProgress }}%
              </td>
              <td>
                <template v-if="item.profile">
                  <template v-if="item.profile.profileRequestAt">
                    {{ Helpers.formatDateTime(item.profile.profileRequestAt, 'DD MMM YYYY') }}
                  </template>
                  <template v-else>-</template>
                </template>
                <template v-else>-</template>
              </td>
              <td class="text-capitalize">
                <template v-if="item.profile">
                  <template v-if="item.profile.profileRequestStatus">
                    <span v-if="item.profile.profileRequestStatus == 'accepted'">
                      Approved
                    </span>
                    <span v-else>
                      {{ item.profile.profileRequestStatus }}
                    </span>
                    <leaderboardRequest
                      :manage="TokenService.getPermissions('manage_leaderboard')"
                      :profile="item.profile"
                      :index="index"
                      @emitStatusUpdated="leaderBoardRequestUpdated"
                    ></leaderboardRequest>
                  </template>
                  <template v-else>-</template>
                </template>
                <template v-else>-</template>
              </td>
              <td>
                <leaderboardRequestHistory
                  v-if="item.profile && item.profile.leaderboard_profile_request_history && item.profile.leaderboard_profile_request_history.length > 0"
                  :manage="TokenService.getPermissions('manage_leaderboard')"
                  :profile="item.profile"
                  :index="index"
                ></leaderboardRequestHistory>
                <span v-else>-</span>
              </td>
              <td>
                <template v-if="item.profile">
                  <template v-if="item.profile.latestReport">
                    <template v-if="item.profile.latestReport.lastPlayedAt">
                      {{ item.profile.latestReport.lastPlayedAt }}
                    </template>
                  </template>
              </template>
              </td>
            </tr>
          </b-tbody>
        </b-table-simple>
        <div class="mx-2 mt-1">
          <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div class="dataTables_info mt-1">Showing {{get_top_users.from}} to {{get_top_users.to}} of {{get_top_users.total}} entries</div>
            <pagination 
              class="mt-1 mb-0"
              :data="get_top_users"
              :limit=5
              @pagination-change-page="getTopUsers"
            ></pagination>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BOverlay,
  BLink,
  BBadge,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTableSimple,
  BThead,
  BFormCheckbox,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import * as _ from "lodash";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Helpers from '../Helpers';
import TokenService from "../TokenService";
import draggable from "vuedraggable";
import leaderboardRequest from "./leaderboardRequest.vue";
import leaderboardRequestHistory from "./leaderboardRequestHistory.vue";
export default {
  components: {
    BOverlay,
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTableSimple,
    BFormCheckbox,
    BThead,
    BTr,
    BTh,
    ToastificationContent,
    BTd,
    BTbody,
    BTfoot,
    BFormInput,
    vSelect,
    BSpinner,
    draggable,
    leaderboardRequest,
    leaderboardRequestHistory,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      drag: false,
      classId: "",
      show: false,
      refreshScreen: 0,
      alertData: "",
      errorMsg: "",
      loading: false,
      TokenService,

      record_filter: {
        grade: '',
        search: '',
        profileRequestStatus: '',
      },
      get_top_users: {},
      get_grades: [],
      get_request_statuses: [],
      Helpers,
    };
  },
  mounted() {
    // this.bearerToken = "Bearer " + localStorage.getItem("_t")
  },
  created() {
    if(!TokenService.getPermissions("view_leaderboard")) {
      this.$router.push("/error-404");
    }
    this.getTopUsers();
  },
  methods: {
    filterAppUser() {
      this.show = true;
      setTimeout(() => {
        this.getTopUsers(1,this.record_filter)
        this.show = false;
      }, 1000);
    },
    setPermission() {
      if (localStorage.getItem("_t")) {
        axios
          .get(process.env.VUE_APP_API_URL + "/tokencheck", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            if (get_response.data.message == "authenticated") {
              var permissiondata = JSON.stringify(get_response.data.permission);
              if (permissiondata) {
                localStorage.setItem("permission", permissiondata);
              }
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.data.message == "Unauthenticated.") {
                localStorage.removeItem("permission", { permission: [] });
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      }
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    getTopUsers: function (page = 1) {
      var url = process.env.VUE_APP_API_URL + `/leaderboard/users?page=${page}`;
      const payload = this.record_filter
      this.show = true;
      axios
        .post(url, payload, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.get_top_users = res.data.topUsers;
          this.get_grades = res.data.getGrades;
          this.get_request_statuses = res.data.leaderBoardRequestStatuses;
          if(res.data.getGrades.length > 0 && !this.record_filter.grade){
            this.record_filter.grade = res.data.getGrades[0]['_id'];
          }
          this.show = false;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    leaderBoardRequestUpdated() {
      this.getTopUsers(this.get_top_users.current_page)
    },
  },
};
</script>
<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  .add-catrgory-button {
    margin-top: 10px;
  }
</style>
<style>
  .vs__open-indicator {
    fill: #9d9d9d !important;
  }
</style>
 